<template>
  <div>
    <!--	Banner	-->
    <div class="Banner">
      <div class="Banner_content">
        <div class="BannerLeft">
          <h2 class="name">大总管</h2>
          <h1 class="container">
            <span>低成本、社交裂变、便捷购物。</span>
            <br>
            <span>首选有大总管商城</span>
          </h1>
          <div class="desc">一套系统带给你极致的商城体验</div>
          <ul class="detail-list">
            <li class="icon-shop listLi">快速搭建</li>
            <li class="icon-guest listLi">全网裂变拓客</li>
            <li class="icon-promotion listLi">全渠道推广</li>
            <li class="icon-private ">抢占私域先机</li>
          </ul>
          <div class="FreeTrialBtn">
            <el-button type="primary" @click="SignIn()">免费试用</el-button>
            <div class="clue-button-extra">或致电咨询：400-618-2019</div>
          </div>
        </div>
        <img class="BannerRight"
             src="https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/%E5%BE%AE%E5%95%86%E5%9F%8E.png"/>
      </div>
    </div>

    <!--  section 一  -->
    <div class="section-1 section">
      <h2>
        <span>一个好的微商城将成为您的生意增长新引擎</span>
      </h2>
      <p class="desc">大总管微商城6大优势 助力商家轻松实现销量暴增</p>
      <dl class="advantage-list">
        <div class="advantage-item icon-smart" v-for="(item,index) in list" :key="index">
          <div class="advantageText">
            <dt>{{ item.dt }}</dt>
            <dd>{{ item.dd }}</dd>
          </div>
          <div class="BackgroundGarden">
            <icon-svg :iconClass='item.icon'></icon-svg>
          </div>
        </div>
      </dl>

      <div class="clue-button-wrapper">
        <el-button type="primary" style="margin-bottom: 10px" @click="SignIn()">
          免费体验系统全部能力
        </el-button>
        <p class="desc">或致电咨询：400-618-2019</p>
      </div>
    </div>

    <!--  section 二  -->
    <div class="section-2 section">
      <h2>
        <span>大总管商城 助力商家成功</span>
      </h2>
      <p class="desc">深入商家业务场景 做出更贴合商家需求的系统</p>

      <el-tabs v-model="activeName">
        <el-tab-pane :label="item.type" :name="item.name" v-for="(item,index) in tabsList" :key="index">
          <div class="swiper-slide">
            <div class="case-detail">
              <div class="info">
                <div class="title">
                  <img class="shop-icon" :src="item.logo">
                  <span class="shop-name">{{item.title}}</span>
                  <span class="shop-category">{{item.type}}</span>
                </div>
                <div class="num">
                  <div class="num-left">
<!--                    <span class="shop-value">{{item.shopValue}}</span>-->
<!--                    <span class="shop-unit">{{item.shopUnit}}</span>-->
<!--                    <span class="num-desc">{{item.numDesc}}</span>-->
                  </div>
                </div>
                <div class="description">
                  <h3 class="description-title">{{item.descriptionTitle}}</h3>
                  <p class="description-desc">{{item.descriptionDesc}}</p>
                </div>
              </div>
              <el-image class="code-img"
                  style="width: 210px; height: 400px"
                  :src="item.img"
                  fit="contain"></el-image>

            </div>
          </div>
        </el-tab-pane>
      </el-tabs>


      <div class="clue-button-wrapper">
        <el-button type="primary" style="margin-bottom: 10px" @click="SignIn()">
          免费体验系统全部能力
        </el-button>
        <p class="desc">或致电咨询：400-618-2019</p>
      </div>
    </div>

<!--    三-->
    <div class="section-3 maxsection">
      <h2>
        <span>大总管微商城将带给您全新的卖货增长体验</span>
      </h2>
      <p class="desc">全渠道一体化电商经营解决方案 让卖货更简单</p>

      <ul class="advantage-list">
        <li class="advantage-item shop">
          <div class="content">
            <h3>双平台一键卖货<br>海量商品也能卖爆全网</h3>
            <p>同步管理各个平台的商品信息<br>无需繁琐设计，商城美入人心</p>


            <div class="clue-button-wrapper">
              <el-button type="primary" @click="SignIn()">
                免费体验全渠道私域商城
              </el-button>
              <p class="desc">或致电咨询：400-618-2019</p>
            </div>

          </div>

          <img class="desc-img" src="https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/%E8%90%A5%E9%94%80%E7%8E%A9%E6%B3%95.png" alt="多平台一键开店，海量商品也能卖爆全网">
        </li>
        <li class="advantage-item marketing">
          <div class="content">
            <h3>多种营销玩法<br>瞬间流量变销量</h3>
            <p>商城自带营销工具，满足线下线上全场景需求，涨销量、提客单、增复购、社交裂变一步到位</p>


            <div class="clue-button-wrapper">
              <el-button type="primary" @click="SignIn()">
                免费体验全渠道私域商城
              </el-button>
              <p class="desc">或致电咨询：400-618-2019</p>
            </div>

          </div>

          <img class="desc-img" src="https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/FmXBHf-4TMquxVMiV5UAWNZrpt4T.png" alt="百种营销玩法，瞬间流量变销量">
        </li>
        <li class="advantage-item social">
          <div class="content">
            <h3>便捷的访问与使用<br>低成本运营与快速上线</h3>
            <p>提供包括商品管理、会员系统、促销功能等在内的完善商城管理体系<br>帮助商家快速打开商品销路</p>


            <div class="clue-button-wrapper">
              <el-button type="primary" @click="SignIn()">
                免费体验全渠道私域商城
              </el-button>
              <p class="desc">或致电咨询：400-618-2019</p>
            </div>

          </div>

          <img class="desc-img" src="https://img01.yzcdn.cn/upload_files/2021/09/14/FmAnApLxjnuqiwgRtFZlnJvsITf-.png" alt="社交分销提销量，人人都是你的卖货员">
        </li>

      </ul>

<!--      <div class="clue-button-wrapper">-->
<!--        <el-button type="primary" style="margin-bottom: 10px">-->
<!--          免费体验系统全部能力-->
<!--        </el-button>-->
<!--        <p class="desc">或致电咨询：400-618-2019</p>-->
<!--      </div>-->
    </div>
<!-- 四 -->
    <div class="section-4 section">
      <h2>
        <span>马上试用有大总管商城，见证订单全新增长</span>
      </h2>
      <p class="desc">大总管商城 助力商家轻松实现销量暴增</p>


      <div class="clue-button-wrapper">
        <el-button type="primary" style="margin-bottom: 10px" @click="SignIn()">
          免费体验系统全部能力
        </el-button>
        <p class="desc">或致电咨询：400-618-2019</p>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: '1',
      tabsList:[
        {
          title:'闰思优品',
          type:'百货商城',
          shopValue:100,
          shopUnit:'万',
          numDesc:'销售额',
          descriptionTitle:"线上线下打通融合",
          descriptionDesc:'“每次做活动时，不仅业绩火爆，门店客流也是爆满状态”。',
          name:'1',
          logo:'https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/%E6%B6%A6%E4%B8%9D%E6%B2%B9%E5%93%81.png',
          img:"https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/6d5d05c97415e076c3baa1f49f014e2.png",
        },
        {
          title:'大甄选',
          type:'百货商城',
          shopValue:10,
          shopUnit:'万',
          numDesc:'销售额',
          descriptionTitle:"线上线下打通融合",
          descriptionDesc:'“每次做活动时，不仅业绩火爆，门店客流也是爆满状态”。',
          name:'2',
          logo:'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/logo3.png',
          img:"https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/%E5%A4%A7%E7%94%84%E9%80%89.jpg",
        },
      ],
      list: [
        {
          dt: '跨平台便利性',
          dd: '无缝对接小程序与PC端，覆盖更广的用户群体，实现双平台市场占领先机',
          icon: 'pingtai',
        },
        {
          dt: '快速交易流程',
          dd: '支持微信内直接下单与支付，简化购物流程，提升用户体验',
          icon: 'jiaoyiliucheng',
        },
        {
          dt: '海量用户触达',
          dd: '依托微信庞大的活跃用户基数，潜在客户资源丰富',
          icon: 'hezuohuoban',
        },
        {
          dt: '强社交互动',
          dd: '通过打折促销、积分奖励等手段增强用户粘性与活跃度',
          icon: 'shejiao',
        },
        {
          dt: '精准营销效果',
          dd: '利用社交数据实现精准定位目标顾客，反馈快，互动性强',
          icon: 'yingxiao',
        },
        {
          dt: '成本效率优势',
          dd: '相比传统渠道，营销成本更低而效果显著，有助于构建庞大消费会员体系',
          icon: 'shuimianxiaolv',
        }
      ]
    }
  },
  methods:{

    SignIn(){
      //注册
      // window.open('http://rhdgjzc.ronghengzn.com/', '_blank');
    }


  }
}
</script>
<style scoped lang="less">
@theme: #364A6B;
@theme-lightGray: #f6f6f6;

@subtopic: #6786b9;
@text-white: #fff;
.Banner {
  //background-color: @theme;
  background: linear-gradient(90deg, rgba(255, 0, 0, .07), rgba(255, 0, 0, .035));
  width: 100vw;

  .Banner_content {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 6;
    padding-top: 60px;
    height: 500px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .BannerLeft {
    .name {
      padding-left: 12px;
      margin-bottom: 50px;
    }

    .container {
      font-weight: 500;
    }

    .desc {
      margin-top: 20px;
      padding-left: 12px;
      font-size: 24px;
      line-height: 28px;
      color: #646566;
      margin-bottom: 18px;
    }

    .detail-list {
      padding-left: 12px;
      font-size: 15px;
      line-height: 42px;
      color: #ff5252;
      margin-bottom: 10px;

      li {
        display: inline-block;
        white-space: nowrap;
        margin-bottom: 0;
        margin-top: 0;
      }

      .listLi:before {
        width: 18px;
        height: 16px;
      }

      .listLi:after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: 0 8px;
        background-color: #fa807d;
      }
    }

    .clue-button-extra {
      margin-top: 10px;
      line-height: 1;
      color: #9c9c9c;
      font-size: 14px;
    }

    .FreeTrialBtn {
      padding-left: 12px;
    }
  }

  .BannerRight {
    height: 430px;
    display: block;
    margin: 80px 20px 0 0;
  }
}

.section {
  width: 1200px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 16px;
    font-weight: 500;
    text-align: center;
  }

  .desc {
    font-size: 20px;
    line-height: 28px;
    color: #969799;
    margin-bottom: 64px;
  }
}
.maxsection{
  h2 {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 16px;
    font-weight: 500;
    text-align: center;
  }

  .desc {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: #969799;
    margin-bottom: 64px;
  }
}
.section-1 {
  margin-top: 100px;
  margin-bottom: 104px;

  .advantage-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 48px;
  }

  .advantage-item {
    position: relative;
    width: 368px;
    border: 1px solid #ff5252;
    border-radius: 4px;
    padding: 23px 27px;
    margin-bottom: 40px;
    background-size: 64px 64px;
    background-position: 263px 43px;
    background-repeat: no-repeat;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .advantageText {
      width: 75%;
    }

    .BackgroundGarden {
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      border-radius: 50%;
      background-color: #FFF3F3;
    }

    dt {
      font-size: 20px;
      line-height: 24px;
      color: #040505;
      margin-bottom: 8px;
      font-weight: 600;
    }

    dd {
      max-width: 201px;
      font-size: 16px;
      line-height: 24px;
      color: #969799;
      margin-top: 0;
      margin-bottom: .5em;
      margin-left: 0;
    }
  }
}

.section-2 {
  .swiper-slide {
    height: 466px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    line-height: 0;
    padding: 21px 0;
    width: 800px;
    margin: 10px auto 100px;
  }

  .case-detail {
    display: inline-block;
    width: 580px;
  }

  .info {
    width: 288px;
    margin: 72px 30px 0 37px;
    float: left;
    text-align: left;

    .title {
      width: 288px;
      height: 40px;
      margin: 0 0 28px;
      position: relative;
      overflow: hidden;
    }

    .shop-icon {
      width: 40px;
      height: 40px;
      float: left;
    }

    .shop-name {
      max-width: 159px;
      font-size: 24px;
      color: #323233;
      line-height: 30px;
      font-weight: 500;
      margin: 5px 11px 5px 8px;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .shop-category {
      float: left;
      font-size: 16px;
      line-height: 16px;
      color: #969799;
      padding-left: .5em;
      margin-top: 16px;
      border-left: 1px solid #969799;
    }

    .num {
      width: 360px;
      height: 54px;
      margin: 0 0 26px;

      .num-left {
        float: left;
        width: 175px;
        height: 54px;
        margin: 0 -15px 0 0;
        position: relative;
      }

      .shop-value {
        display: block;
        height: 28px;
        font-size: 28px;
        color: #323233;
        line-height: 28px;
        float: left;
        margin-right: 2px;
        font-weight: 600;
        font-family: Avenir;
      }

      .shop-unit {
        width: 30px;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #323233;
        line-height: 24px;
        float: left;
        margin-top: 5px;
      }

      .num-desc {
        position: absolute;
        width: 147px;
        height: 24px;
        font-size: 16px;
        color: #969799;
        line-height: 24px;
        font-weight: 400;
        top: 30px;
        right: 18px;
        left: 0;
      }
    }

    .description {
      width: 288px;
      height: 81px;

      .description-title {
        width: 280px;
        height: 28px;
        margin-bottom: 5px;
        font-size: 18px;
        color: #323233;
        line-height: 28px;
        font-weight: 500;
      }

      .description-desc {
        max-width: 288px;
        height: 72px;
        font-size: 16px;
        color: #646566;
        line-height: 24px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

  }

  .code-img {
    float: left;
    //height: 353px;
    //margin: 34px 22px 10px 0;
    margin-top: 10px;
  }
}
.section-3{
  ul{
   margin: 0;
    padding: 0;
    list-style-type: none;

  }
  .advantage-item {
    padding: 0 -webkit-calc((100% - 1440px)/2);
    padding: 0 -moz-calc((100% - 1440px)/2);
    padding: 0 calc((100% - 1440px) / 2);
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
     .content {
      text-align: left;
      width: 640px;
      height: 440px;
      margin: 0 34.5px 0 80px;
      padding: 74px 48px 0 124px;
       h3 {
         font-size: 36px;
         line-height: 48px;
         margin-bottom: 20px;
         font-weight: 500;
       }
        p {
         font-size: 18px;
         line-height: 26px;
         margin-bottom: 32px;
       }
       .clue-button-wrapper {
         display: inline-block;
       }

    }
    .desc-img {
      width: 551px;
      //max-height: 320px;
      margin: 56px 0 64px;
    }
  }
  .advantage-item:nth-of-type(2n) {
    background-color: #f9f9f9;
    padding-top: 48px;
    padding-bottom: 48px;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 700px) {
  .Banner {
    //background-color: @theme;
    background: linear-gradient(90deg, rgba(255, 0, 0, .07), rgba(255, 0, 0, .035));
    width: 100vw;

    .Banner_content {
      padding-top: 0;
      width: 100%;
      position: relative;
      z-index: 6;
      height: 500px;
      overflow: hidden;
    }

    .BannerLeft {
      height: 500px;
      position: relative;
      width: 100%;
      .name {
        display: none;
      }

      .container {
        padding-top: 5px;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
      }

      .desc {
        font-size: 14px;
        text-align: center;
        margin-top: 1px;
        //line-height: 28px;
        color: #646566;
      }

      .detail-list {
        display: none;
      }

      .clue-button-extra {
        margin-top: 10px;
        line-height: 1;
        color: #9c9c9c;
        font-size: 14px;
      }

      .FreeTrialBtn {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;

        //padding-left: 12px;
      }
    }

    .BannerRight {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -100;

      width: 100%;
      display: block;
      //margin: 100px 0 0 0;
    }
  }



  .section {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: 18px;
      line-height: 20px;
      margin-top: 50px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 0;
    }

    .desc {
      font-size: 15px;
      color: #969799;
      margin-bottom: 15px;
    }
  }
  .maxsection{

    h2 {
      display: none;
    }

    .desc {
      display: none;


    }
  }
  .section-1 {

    margin-top: 10px;
    margin-bottom: 10px;

    .advantage-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      text-align: left;
      margin-bottom: 10px;
    }

    .advantage-item {
      position: relative;
      width: 45%;
      border: 1px solid #ff5252;
      border-radius: 4px;
      padding: 13px 17px;
      margin-bottom: 20px;
      background-size: 64px 64px;
      background-position: 263px 43px;
      background-repeat: no-repeat;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .advantageText {
        width: 100%;
      }

      .BackgroundGarden {
        display: none;
      }

      dt {
        font-size: 16px;
        line-height: 24px;
        color: #040505;
        margin-bottom: 8px;
        font-weight: 600;
      }

      dd {
        max-width: 201px;
        font-size: 14px;
        line-height: 16px;
        color: #969799;
        margin-top: 0;
        margin-bottom: .5em;
        margin-left: 0;
      }
    }
  }

  .section-2 {
    display: none;

    .swiper-slide {
      height: auto;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      line-height: 0;
      padding: 21px 0;
      width: 100%;
      margin: 10px auto 100px;
    }

    .case-detail {
      display: inline-block;
      width: 100%;
    }

    .info {
      border: 1px solid red;
      //width: 100%;
      overflow: hidden;
      box-sizing: content-box;

      margin: 20px 0px 0 20px;
      text-align: left;

      .title {
        width: 100%;
        height: 40px;
        margin: 0 0 28px;
        position: relative;
        overflow: hidden;
      }

      .shop-icon {
        width: 40px;
        height: 40px;
        float: left;
      }

      .shop-name {
        max-width: 159px;
        font-size: 24px;
        color: #323233;
        line-height: 30px;
        font-weight: 500;
        margin: 5px 11px 5px 8px;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .shop-category {
        float: left;
        font-size: 16px;
        line-height: 16px;
        color: #969799;
        padding-left: .5em;
        margin-top: 16px;
        border-left: 1px solid #969799;
      }

      .num {
        width: 360px;
        height: 54px;
        margin: 0 0 26px;

        .num-left {
          float: left;
          width: 175px;
          height: 54px;
          margin: 0 -15px 0 0;
          position: relative;
        }

        .shop-value {
          display: block;
          height: 28px;
          font-size: 28px;
          color: #323233;
          line-height: 28px;
          float: left;
          margin-right: 2px;
          font-weight: 600;
          font-family: Avenir;
        }

        .shop-unit {
          width: 30px;
          height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #323233;
          line-height: 24px;
          float: left;
          margin-top: 5px;
        }

        .num-desc {
          position: absolute;
          width: 147px;
          height: 24px;
          font-size: 16px;
          color: #969799;
          line-height: 24px;
          font-weight: 400;
          top: 30px;
          right: 18px;
          left: 0;
        }
      }

      .description {
        width: 288px;
        height: 81px;

        .description-title {
          width: 280px;
          height: 28px;
          margin-bottom: 5px;
          font-size: 18px;
          color: #323233;
          line-height: 28px;
          font-weight: 500;
        }

        .description-desc {
          max-width: 288px;
          height: 72px;
          font-size: 16px;
          color: #646566;
          line-height: 24px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

    }

    .code-img {
      display: none;
      float: left;
      width: 30%;
      margin: 34px 22px 30px 0;
    }
  }
  .section-3{
    ul{
      margin: 0;
      padding: 0;
      list-style-type: none;

    }
    .advantage-item {
      padding: 0 20px;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      position: relative;

      text-align: center;


      .content {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        height: auto;



        h3 {
          text-align: center;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 20px;
          font-weight: 500;
        }

         p {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #969799;
          margin-bottom: 32px;
        }




        .clue-button-wrapper {
          display: inline-block;
          //position: absolute;
          //bottom: 0;
          //left: 0;
          padding-bottom: 20px;
          width: 100%;

        }
      }
      .desc-img {
        width: 224px;
        margin: 0 auto 134px;
        //height: 188px;
        max-height: 320px;
      }
    }
    .advantage-item:nth-of-type(2n) {
      background-color: #f9f9f9;
      padding-top: 48px;
      padding-bottom: 48px;
    }
    .clue-button-wrapper{
      display: none;
    }
  }
}




</style>